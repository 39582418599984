<template>
	<div>
		<div class="header">
			<div class="top top_mafi f-left">
				<div class="util">
					<button
						@click="$emit('cancel')"
						class="arrow_white"
					>
						<i class="icon-arrow-left"></i>
					</button>
				</div>
				<h2 class="page_tit">{{ program.title }}</h2>
			</div>
			<div class="save">
				<button
					@click="$emit('click')"
					class="btn_save"
				>확인</button>
			</div>
		</div>
		<!-- //고정해더 -->
		<div class="section_wrap pt-50">
			<div class="container">
				<div class="subindex_mainbox">
					<div class="input_area">
						<h2 class="itemlist_head col_basic">작성</h2>
						<ul class="settings_list">
							<li class="settings_list_item listcheck_item">
								<div class="bright_checks">
									<input
										v-model="board_config.all_write_fg"
										type="checkbox" id="wr1"
									>
									<label for="wr1">전체</label>
								</div>
							</li>
							<li class="settings_list_item listcheck_item">
								<div class="bright_checks">
									<input
										v-model="board_config.board_authority[0].reg_fg"
										type="checkbox" id="wr2"
									>
									<label for="wr2">보스</label>
								</div>
							</li>
							<li class="settings_list_item listcheck_item">
								<div class="bright_checks">
									<input
										v-model="board_config.board_authority[1].reg_fg"
										type="checkbox" id="wr3"
									>
									<label for="wr3">언더보스</label>
								</div>
							</li>
						</ul>
					</div>
					<div class="input_area">
						<h2 class="itemlist_head col_basic">읽기</h2>
						<ul class="settings_list">
							<li class="settings_list_item listcheck_item">
								<div class="bright_checks">
									<input
										v-model="board_config.all_read_fg"
										type="checkbox" id="rd1"
									>
									<label for="rd1">전체</label>
								</div>
							</li>
							<li class="settings_list_item listcheck_item">
								<div class="bright_checks">
									<input
										v-model="board_config.board_authority[0].inquiry_fg"
										type="checkbox" id="rd6"
									>
									<label for="rd6">보스</label>
								</div>
							</li>
							<li class="settings_list_item listcheck_item">
								<div class="bright_checks">
									<input
										v-model="board_config.board_authority[1].inquiry_fg"
										type="checkbox" id="rd5"
									>
									<label for="rd5">언더보스</label>
								</div>
							</li>
							<li class="settings_list_item listcheck_item">
								<div class="bright_checks">
									<input
										v-model="board_config.board_authority[4].inquiry_fg"
										type="checkbox" id="rd2"
									>
									<label for="rd2">시민</label>
								</div>
							</li>
							<template
								v-if="false"
							>
							<li class="settings_list_item listcheck_item">
								<div class="bright_checks">
									<input
										v-model="board_config.board_authority[3].inquiry_fg"
										type="checkbox" id="rd3"
									>
									<label for="rd3">갱스터</label>
								</div>
							</li>
							<li class="settings_list_item listcheck_item">
								<div class="bright_checks">
									<input
										v-model="board_config.board_authority[2].inquiry_fg"
										type="checkbox" id="rd4"
									>
									<label for="rd4">카포</label>
								</div>
							</li>

							</template>
						</ul>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>


<script>
export default {
	name: 'mafia126'
	, props: ['user', 'cartel', 'board_config']
	, data: function(){
		return {
			program: {
				name: '게시판 권한 설정'
				, title: '게시판 권한 설정'
				, not_footer: true
				, not_header: true
				, type: 'cartel_sub'

			}
			, item_cartel: this.cartel
			, item_board_config: this.board_config
		}
	}
	, computed: {
	}
	, methods: {

	}
	,created() {
		this.$bus.$emit('onLoad', this.program)
	}
}
</script>

